/* General styling for the web chat */
html, body {
    height: 100%;
    margin: 0;
    min-width: 360px;
    font-family: 'Noto Sans TC', 'Lexend', sans-serif;
}

#rasaWebchatPro {
    height: 100%;
    width: 100%;
}

#rasaWebchatPro,
.rw-widget-embedded .rw-conversation-container {
    background-image: url('assets/Background.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.rw-messages-container {
    background: url('assets/TMDHC.png') no-repeat bottom 15px left 15px !important;
    background-size: 59px 50px !important;
}

/* Style for the bot and user messages */

.rw-conversation-container .rw-response {
    background-color: #FFDAA4 !important;
    color: rgb(31, 41, 55) !important;
    box-shadow:  2px 2px #787878;
    border-radius: 0px 30px 30px 30px !important;
}

.rw-from-client .rw-client,
.rw-from-response .rw-reply {
    background-color: white !important;
    color: #000 !important;
    /*padding-left: 0px !important;*/
}

.rw-conversation-container .rw-client{
    border-radius: 30px 0 30px 30px !important;
    box-shadow:  2px 2px #787878;
}


.rw-from-response .rw-reply {
    border: none !important;
    border-radius: 8px;
    font-weight: normal !important;
}

@media (max-width: 768px) {
    .rw-conversation-container .rw-replies {
        margin-left: 0 !important;
    }

    .rw-conversation-container .rw-reply {
        max-width: 100% !important;
        width: 100% !important;
        margin: 0.2em 0 !important;
        justify-content: center !important;
    }
}

.rw-conversation-container .rw-from-response .rw-message-text {
    padding-left: 33px;
}

/* Bot & user message icon */


.rw-from-response .rw-message .rw-message-text::before {
    background: url('assets/iconA.png') no-repeat;
    content: "";
    margin-left: -33px;
    /*margin-right: 8px;*/
    width: 25px;
    height: 25px;
    display: block;
    float: left;
    background-size: contain !important;
}

.rw-from-client .rw-message .rw-message-text::before  {
    /*background: url('assets/iconA.png') no-repeat;*/
    content: "";
    /*padding-left: 33px;*/
    /*margin-left: -33px;
    margin-right: 8px;*/
    width: 15px;
    height: 25px;
    /*display: block;
    float: left;*/
    background-size: contain !important;
}
/*.rw-from-client .rw-message .rw-message-text::before {
    background: url('assets/iconB.png') no-repeat;
}*/

/* Input text field styling */
.rw-conversation-container .rw-new-message {
    border-radius: 30px;
    padding: 10px;
}

.rw-conversation-container .rw-new-message::placeholder {
    color: #787878 !important;
}

.rw-sender {
    background-color: #E0E0E0 !important;
    padding: 15px 10px !important;
}

.rw-sender input {
    color: #787878 !important;
    font-family: 'Noto Sans TC', 'Lexend', sans-serif;
}

.rw-send {
    background: transparent url('assets/send.png') no-repeat center !important;
    background-size: contain !important;
    border: none;
    margin-left: 8px;
}


.rw-carousel-container .rw-carousel-card {
    background: #FFF !important;
}

.rw-carousel-container .rw-carousel-card .rw-reply {
    background-color: #FFDAA4 !important;
    width: auto !important;
    margin: 0 10px !important;
}

.rw-conversation-container .rw-send .rw-send-icon-ready {
    fill: none !important;
}